"use client";
import BrandBadge from "@/components/shared/badges/BrandBadge";
import Image from "next/image";
import React, { useEffect } from "react";
import projectSmallImage3 from "@/assets/img/project/project__small__img__2.png";
import ProjectCard from "@/components/shared/cards/ProjectCard";
import ButtonPrimary from "@/components/shared/buttons/ButtonPrimary";
import { getProjects } from "@/libs/sanity/config";
import { useCookies } from "next-client-cookies";
import { useTranslations } from "next-intl";

const Projects = () => {
  const [projects, setProjects] = React.useState([]);
  const t = useTranslations("Project");
  const coockies = useCookies();
  const lang = coockies.get("lang") || "az";
  useEffect(() => {
    getProjects(lang).then((data) => {
      setProjects(data);
    });
  }, [lang]);
  return (
    <div
      className="project special__spacing"
      id="project__area"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div className="project__margin">
        <div className="container  sp_bottom_60 ">
          <div className="row align-items-center sp_bottom_80">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12">
              <div className="section__title section__title--2">
                <div className="section__title__heading">
                  <h3 className="mb-0">
                    {" "}
                    {t("ourProjects")}
                    <span className=" text__unerline">
                      {t("CONSULTING")}
                    </span>{" "}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
              <div className="project__section__img text__end">
                <BrandBadge />
              </div>
            </div>
            <div className="row">
              {projects &&
                projects
                  .slice(0, 3)
                  ?.map((project, idx) => (
                    <ProjectCard key={idx} project={project} />
                  ))}
            </div>
          </div>
          {/* <div
            className="container project__bottom"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="project__bottom__button">
                  <ButtonPrimary text={t("view_all")} path="/projects" />
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="project__bottom__img text-center">
                  <Image src={projectSmallImage3} alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="project__bottom__text">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor{" "}
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Projects;
