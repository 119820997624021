import React from "react";
import ButtonPrimary from "../buttons/ButtonPrimary";

import {SanitImageUrl} from "@/libs/sanity/config";
import  {CustomImage} from "@/components/shared/Customİmage/Customİmage";

const HeroSlide = ({ slide }) => {
  const { heading, description, image } = slide;

  return (
    <div className="row align-items-center height__950">
      <div
        className="col-xl-6 col-lg-6 col-md-12 col-sm-12"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="herobanner__content__wrapper">
          <div className="herobanner__title">
            <h1>{heading}</h1>
          </div>
          <div className="herobanner__text">
            <p>{description}</p>
          </div>
          <div className="herobanner__button">
            <ButtonPrimary path={"/services"} text="OUR ALL SERVICES" />
          </div>
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
        <div className="herobanner__img herobanner__img--position">
          <CustomImage alt={heading} width={500} height={200}     src={SanitImageUrl(image)?.url() ?? undefined}/>

        </div>
      </div>
    </div>
  );
};

export default HeroSlide;
