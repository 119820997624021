"use client";
import ButtonPrimary from "@/components/shared/buttons/ButtonPrimary";
import { getBlogs } from "@/libs/sanity/config";
import { useEffect, useState } from "react";
import BlogCard3 from "@/components/shared/cards/BlogCard3";
import { useTranslations } from "next-intl";

const Blogs = ({ blogsType }) => {
  const [blogs, setBlogs] = useState([]);
  const t = useTranslations("Blog");
  useEffect(() => {
    getBlogs().then((data) => {
      setBlogs(data?.blogs);
    });
  }, []);

  return (
    <div
      className={`blog sp_bottom_140   ${
        blogsType === 2 ? "" : "pink__bg__color"
      }`}
      id="blog__area"
      data-aos="fade-up"
      data-aos-duration="1500"
    >
      <div className="container">
        <div className="row align-items-center sp_bottom_80">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="section__title section__title--2">
              <div className="section__title__heading">
                <h3 className="mb-0">
                  {t("ULTIMATE")}
                  <span className="text__gradient text__unerline">
                    {t("INNOVATION")}
                  </span>{" "}
                  {t("ARTICLES")}.
                </h3>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div className="blog__section__button text__end">
              <ButtonPrimary text={t("view_all")} path="/blogs" />
            </div>
          </div>
        </div>
        <div className="row">
          {blogs &&
            blogs
              .slice(0, 3)
              ?.map((blog, idx) => <BlogCard3 key={idx} blog={blog} />)}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
