"use client"
import React,{useState} from 'react'
import Image from "next/image";
import s from "./customImage.module.css"
import clsx from "clsx";


export const CustomImage = ({
    className,src,alt,...rest
                      }) => {
    const [loading, setLoading] = useState(true)
    return (
        <Image src={src} alt={alt}
               className={clsx(loading?s.root:undefined, className)}
                {...rest}
            sizes={
                "(max-width: 639px) 100vw, (max-width: 767px) 728px, (max-width: 1023px) 900px, 528px"
            }
                loading={loading ? "eager" : "lazy"}
                onLoad={() => setLoading(false)}
        />
    )
}
