"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useEffect, useState } from "react";
import HeroIcons from "@/components/shared/hero-banner/HeroIcons";
import HeroSlide from "@/components/shared/hero-banner/HeroSlide";
import {getHomeBanner} from "@/libs/sanity/config";
import {useCookies} from "next-client-cookies";
const Hero1 = () => {
  const[sliders, setSliders] = useState([])
  const cookies = useCookies();
  const lang=cookies.get("lang")||"az"

  useEffect(() => {
    getHomeBanner(lang).then((data) => {
        setSliders(data)
    }
    )
  }, [lang]);


  return (
    <div
      className="herobanner herobanner__with__transparent__header">
      <div className="container">
        <div className="herobanner__wrapper">
          <Swiper
            grabCursor={true}
            slidesPerView={1}
            navigation={true}
            modules={[Navigation]}
            className="herobanner__slider__active"
          >
            {sliders&&sliders?.map((slide, idx) => (
              <SwiperSlide
                key={idx}
                className="herobanner__single position-relative"
              >
                <HeroSlide slide={slide} />
              </SwiperSlide>
            ))}

            <div className="slider__controls__wrap slider__controls__pagination slider__controls__arrows herobanner__arrow__1">
              <div className="swiper-button-next arrow-btn">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4297 5.92999L20.4997 12L14.4297 18.07"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.5 12H20.33"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="swiper-button-prev arrow-btn">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.57031 5.92999L3.50031 12L9.57031 18.07"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 12H3.67"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* <div className="swiper-pagination"></div> --> */}
            </div>
          </Swiper>
        </div>
      </div>
      <HeroIcons />
    </div>
  );
};

export default Hero1;
