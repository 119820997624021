import ButtonPrimary from "../buttons/ButtonPrimary";
import Link from "next/link";
import Image from "next/image";
import { SanitImageUrl } from "@/libs/sanity/config";
import dayjs from "dayjs";

const BlogCard3 = ({ blog, blogsType, bg }) => {
  const { slug, heading, image, description, createAt, duration } = blog;

  return (
    <div
      className={` ${
        bg === "pink"
          ? "col-xl-4 col-lg-6 col-md-12 col-sm-12"
          : "col-xl-4 col-lg-4 col-md-6 col-sm-6"
      }  col-12`}
      data-aos="fade-up"
      data-aos-duration={duration}
    >
      <div
        className={`blog__4__single ${
          blogsType === 4 ? "" : " blog__3__single bg-gray-900"
        } single__transform ${bg === "pink" ? "bg-gray-900" : ""}`}
      >
        <div className="blog__4__img">
          <Image
            src={image && SanitImageUrl(image)?.url()}
            alt={description}
            width={300}
            height={300}
          />
        </div>
        <div className="blog__4__time">
          <p className={bg === "pink" ? "bg__white" : ""}>
            <span className="text__gradient ">
              {dayjs(createAt).format("MMM DD, YYYY")}
            </span>
          </p>
        </div>
        <div className="blog__4__heading">
          <h6>
            <Link className="text-uppercase" href={`/blogs/${slug?.current}`}>
              {heading?.length > 18 ? heading?.slice(0, 18) + "..." : heading}
            </Link>
          </h6>
        </div>
        <div className="blog__4__text">
          <p>{description}</p>
        </div>
        <div className="blog__4__button">
          <ButtonPrimary text={"READ MORE"} path={`/blogs/${slug?.current}`} />
        </div>
      </div>
    </div>
  );
};

export default BlogCard3;
