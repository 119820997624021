"use client";
import ServiceCard from "@/components/shared/cards/ServiceCard";

import { useEffect, useState } from "react";
import { getServices, getServicesHeading } from "@/libs/sanity/config";
import { useCookies } from "next-client-cookies";

const OfferedServices = ({ service, pb }) => {
  const [services, setServices] = useState([]);
  const [heading, setHeading] = useState([]);
  const coockie = useCookies();
  const lang = coockie.get("lang") || "az";
  useEffect(() => {
    getServices(lang).then((data) => {
      setServices(data);
      getServicesHeading(lang).then((data) => {
        setHeading(data);
      });
    });
  }, [lang]);

  return (
    <div
      className={`service sp_top_140 special__spacing ${pb ? pb : "sp_bott"}`}
      id="service__area"
      data-aos="fade-up"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="section__title text-center sp_bottom_90">
              {service === 2 ? (
                <div className="section__title__button">
                  <span>Our Service</span>
                </div>
              ) : (
                ""
              )}
              <div className="section__title__heading">
                <h3>{heading[0]?.heading}</h3>
              </div>
              <div className="section__title__text">
                <p>{heading[0]?.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {services &&
            services
              .slice(0, 4)
              ?.map((service, idx) => (
                <ServiceCard key={idx} service={service} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default OfferedServices;
