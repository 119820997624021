"use client";
import Blogs from "@/components/sections/blogs/Blogs";
import Hero1 from "@/components/sections/hero-banners/Hero1";
import Projects from "@/components/sections/projects/Projects";

import OfferedServices from "@/components/sections/services/OfferedServices";
import PinkColor from "@/components/shared/others/PinkColor";
import { useHeaderContex } from "@/providers/HeaderContex";

const IndexMain = () => {
  const { isOnepage } = useHeaderContex();
  return (
    <main>
      <Hero1 />
      <OfferedServices />
      {/* <Video /> */}
      {/* <About /> */}
      {/* <Team /> */}
      <PinkColor />
      <Projects />
      {/* <Testimonials /> */}
      {/* {isOnepage ? <Contact1 /> : ""} */}
      {/*<CounterUp />*/}
      {/*<Brands />*/}
      <Blogs />
    </main>
  );
};

export default IndexMain;
